export default {

    /*
    |--------------------------------------------------------------------------
    | Resource Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in resource links, actions etc.
    |
    */

    'create': 'Создать :name',
    'show': 'Просмотреть :name',
    'edit': 'Редактировать :name',
    'delete': 'Удалить :name',
    'edit_image': "Редактировать :name изображение",

    'delete_confirmation_title': 'Вы хотите удалить :name',
    'delete_confirmation_body': "Если не отменить действие, :name больше не будет восстановлено.",

    'fetched': 'Получено :total :names.',
    'not_fetched': 'Ошибка при загрузке :names!',
    'created': ':name создан!',
    'not_created': 'Ошибка создания :name!',
    'updated': ':name sucessfully updated!',
    'not_updated': 'Ошибка обновления :name!',
    'deleted': ':name successfully deleted!',
    'not_deleted': 'Ошибка удаления :name!',
    'restored': ':name successfully restored!',
    'not_restored': 'Ошибка восстановления :name!',

    'create_user': 'Создать пользователя',
    'List': 'Страница',
}
