export default {

    /*
    |--------------------------------------------------------------------------
    | Navigation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in navigation links, page titles, form buttons etc.
    |
    */

    'register_title': 'Регистрация',
    'register_subtitle': 'вашего личного кабинета',
    'signin_guest_title': 'Войти',
    'signin_guest_subtitle': 'с вашей учетной записью',
    'signin_identified_title': 'Добро пожаловать',
    'forgot_email': 'Забыли адрес электронной почты?',
    'forgot_password': 'Забыл пароль?',
    'next': 'Следующий',
    'previous': 'Предыдущий',
    'password_request_title': 'Восстановление аккаунта',
    'password_request_subtitle': "Введите свой адрес электронной почты, и мы пришлем ссылку для восстановления",
    'password_reset_title': 'Сброс пароля',
    'password_reset_subtitle': "Теперь вы можете установить новый пароль",
    'signin': 'Войдите вместо',
    'send_link': 'Отправить ссылку',
    'reset': 'Сброс',
    'analytics': 'Аналитика',

    'github': 'Репозиторий GitHub',

    'monitoring': 'Мониторинг',

    'notifications': 'Уведомления',

    'locale': 'Локализация',
    'english': 'Английский',
    'russian': 'Русский',
    'filipino': 'Филиппинский',

    'nightmode_on': 'Включить ночной режим',
    'nightmode_off': 'Выключить ночной режим',

    'account': 'Аккаунт',
    'settings': 'Настройки',
    'lock': 'Замок',
    'signout': 'Выход',

    'help': 'Помощь',

    'search': 'Поиск',

    'open_drawer': 'Открыть ящик',
    'close_drawer': 'Закрыть ящик',
    'minimize_drawer': 'Свернуть ящик',
    'maximize_drawer': 'Увеличить ящик',

    'dashboard': 'Рабочий стол',
    'resources': 'Ресурсы',
    'users': 'Пользователи',
    'roles': 'Роли',

    'citation': '© 2018 … 2023,',
    'lcc_company': 'BeeSeed',
}
