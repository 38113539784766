export default {


    /*
    |--------------------------------------------------------------------------
    | Password Reset Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are the default lines which match reasons
    | that are given by the password broker for a password update attempt
    | has failed, such as for an invalid token or invalid new password.
    |
    */

    'password': 'Пароли должны состоять минимум из шести символов и соответствовать подтверждению.',
    'reset': 'Ваш пароль был сброшен!',
    'sent': 'Мы отправили вам ссылку для сброса пароля по электронной почте!',
    'token': 'Этот токен сброса пароля недействителен.',
    'user': "Мы не можем найти пользователя с таким адресом электронной почты.",

}
