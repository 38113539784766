export default {

    /*
    |--------------------------------------------------------------------------
    | Table Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in table pagination controls, table toolbar forms etc.
    |
    */

    // Filters
    'filter_list': 'Отфильтровать список',
    'filter_by_label': 'Столбец',
    'filter_type_label': 'Состояние',
    'filter_value_label': 'Значение',
    'string_value_placeholder': 'Строковое значение здесь',
    'numeric_value_placeholder': 'Числовое значение здесь',
    'add_filter': 'Добавить фильтр',
    'eqs': 'Равно',
    'neqs': 'Не равно',
    'gt': 'Больше чем',
    'lt': 'Меньше, чем',
    'gte': 'Больше, чем равно',
    'lte': 'Меньше, чем равно',
    'like': 'Содержит',
    'nlike': 'Не содержит',

    // Sorting
    'sort_asc': 'Сортировать по возрастанию',
    'sort_desc': 'Сортировать по убыванию',

    // Pagination controls
    'per_page': 'Строк на странице',
    'first_page': 'Первая страница',
    'previous_page': 'Предыдущая страница',
    'next_page': 'Следующая страница',
    'last_page': 'Последняя страница',
}
