export default {


    /*
    |--------------------------------------------------------------------------
    | Home Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used during authentication for various
    | messages that we need to display to the user. You are free to modify
    | these language lines according to your application's requirements.
    |
    */


    'user':'Здраствуйте, :name. Ваш логин :username.',
    'title_summary':'Суммарная статистика',
    'title_max_five_billings_by_one_day':'Пять максимальных сайтов по объему трафика за день',
    'title_max_five_billings_by_seven_days':'Пять максимальных сайтов по объему трафика за семь дней',
    'title_five_billings_by_thirty_days':'Пять максимальных сайтов по объему трафика за тридцать дней',
    'username':'Логин',
    'user_name':'Имя',
    'total_size':'Суммарный объем',
    'record':'DNS запись',
    'created_at':'Создано',
    'email':'E-mail',
    'domain':'Домен',
    'origin':'Ориджин',
    'type':'Тип DNS записи',
    'value':'Значение DNS записи',
    'title_last_five_users':'Последние пять зарегистрированных клиентов',
    'title_last_five_sites':'Последние пять подключенных сайтов',
    'last_five_dnsrecords':'Последние пять созданных DNS записей',
    'data':{
        'summary_billing_by_last_day':'Суммарный трафик за последний день',
        'summary_billing_by_last_seven_days':'Суммарный трафик за последние семь дней',
        'summary_billing_by_last_month':'Суммарный трафик за последний месяц',
        },
    'user_need_create_site': ':name! У вас нет подключенных сайтов. Вам необходимо создать новое подключение к CDN.',
    'traffc_on_graf_last_seven_days':'Трафик по FQDN\'ам за последние 7 дней',
    'seeds_on_map_last_seven_days':'Сиды всех FQDN\'ов на карте за последние 7 дней',
    'mb_last_seven_days':'Mb',

}
