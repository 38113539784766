export default {

    /*
    |--------------------------------------------------------------------------
    | Navigation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in navigation links, page titles, form buttons etc.
    |
    */

    'register_title': 'Register',
    'register_subtitle': 'with your Account',
    'signin_guest_title': 'Sign in',
    'signin_guest_subtitle': 'with your Account',
    'signin_identified_title': 'Welcome',
    'forgot_email': 'Forgot Email?',
    'forgot_password': 'Forgot Password?',
    'next': 'Next',
    'previous': 'Previous',
    'password_request_title': 'Account Recovery',
    'password_request_subtitle': "Enter your email and we'll send a recovery link",
    'password_reset_title': 'Reset Password',
    'password_reset_subtitle': "You can now set your new password",
    'signin': 'Sign in instead',
    'send_link': 'Send Link',
    'reset': 'Reset',
    'analytics': 'Analytics',

    'github': 'GitHub Repository',

    'monitoring': 'Monitoring',

    'notifications': 'Notifications',

    'locale': 'Locale',
    'english': 'English',
    'russian': 'Russian',
    'filipino': 'Filipino',

    'nightmode_on': 'Toggle Night Mode On',
    'nightmode_off': 'Toggle Night Mode Off',

    'account': 'Account',
    'settings': 'Settings',
    'lock': 'Lock',
    'signout': 'Sign out',

    'help': 'Help',

    'search': 'Search',

    'open_drawer': 'Open Drawer',
    'close_drawer': 'Close Drawer',
    'minimize_drawer': 'Minimize Drawer',
    'maximize_drawer': 'Maximize Drawer',

    'dashboard': 'Dashboard',
    'resources': 'Resources',
    'users': 'Users',
    'roles': 'Roles',

    'citation': '© 2018 … 2023,',
    'lcc_company': 'BeeSeed',

}
