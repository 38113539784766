import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import i18n from '../../../lang/i18n';
import conf from '../../../conf';

import {
    Button,
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    withStyles,
} from '@material-ui/core';

import {
    AccountCircle as AccountCircleIcon,
    ExpandMore as ExpandMoreIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import * as UrlUtils from '../../helpers/URL';
import * as NavigationUtils from '../../helpers/Navigation';
import { Auth as AuthLayout } from '../layouts';
import { AppContext } from '../../AppContext';

function SignIn(props) {
    const { authenticate } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [identified, setIdentified] = useState(false);
    const [passwordVisible, setPasswordVisibility] = useState(false);
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState({});

    /**
     * Event listener that is called on usernameChip clicked.
     *
     * @return {undefined}
     */
    const handleUsernameChipClicked = () => {
        setUsername('');
        setIdentified(false);

        const { history, location } = props;

        history.push(`${location.pathname}`);
    };

    /**
     * Event listener that is called on password visibility toggle.
     *
     * @return {undefined}
     */
    const handlePasswordVisibilityToggle = () => {
        setPasswordVisibility(!passwordVisible);
    };

    /**
     * This should send an API request to identify the user.
     *
     * @param {string} username
     * @param {object} form
     *
     * @return {undefined}
     */
    const identify = async (username = null, { setErrors }) => {
        setLoading(true);

        try {
            const response = await axios.post(conf.back.host+'/api/v1/auth/identify', { username },
                {
                withCredentials: true,
                crossDomain: true,
                headers: {'Access-Control-Request-Headers': 'X-PINGOTHER, Content-Type',
                    'X-PINGOTHER': 'pingpong',
                    'Content-Type' : 'application/json',
                }
                });


            const { history, location } = props;

            setIdentified(true);
            setUsername(response.data);
            setLoading(false);

            const queryString = UrlUtils.queryString({
                username: response.data,
            });

            if (queryString === location.search) {
                return;
            }

            history.push(`${location.pathname}${queryString}`);
        } catch (error) {
            if (!error.response) {
                throw new Error('Unknown error');
            }

            const { errors } = error.response.data;

            if (errors) {
                setErrors(errors);
            }

            setLoading(false);
        }
    };

    /**
     * This should send an API request to request for authentication.
     * Reload if authenticated.
     *
     * @param {object} values
     * @param {object} form
     *
     * @return {undefined}
     */
    const signIn = async (values, form = {}) => {
        setLoading(true);

        try {
            const  grant_type  = 'password';
            const  client_id  = '0112ee3c-7b23-4b6b-b2fd-62387e0a4599';
            const  client_secret  = 'df0cce5cc6113628cce3e2184a9b0e3c';
            const { password } = values;

            const response = await axios.post(conf.back.host+'/oauth/token', {
                grant_type,
                client_id,
                client_secret,
                username,
                password,
            },
                {
                    withCredentials: true,
                    crossDomain: true,
                    headers: {
                        'Access-Control-Request-Headers': 'X-PINGOTHER, Content-Type',
                        'X-PINGOTHER': 'pingpong',
                        'Content-Type' : 'application/x-www-form-urlencoded',
                    }

                });
            response.data.auth_token = response.data.access_token;
            response.data.token_type = 'bearer';
            console.log('authenticate_user => '+ typeof JSON.stringify(response.data));
           //await authenticate_user(JSON.stringify(response.data));
            authenticate(JSON.stringify(response.data));

            setLoading(false);
        } catch (error) {
            if (!error.response) {
                throw new Error('signIn error'+ error);
            }

            const { errors } = error.response.data;

            if (errors) {
                form.setErrors(errors);
            }

            setLoading(false);
        }
    };

    /**
     * Event listener that is triggered when the sign in form is submitted.
     *
     * @param {object} values
     * @param {object} form
     *
     * @return {undefined}
     */
    const handleSignInSubmit = async (values, form) => {

        form.setSubmitting(false);
        console.log('handleSignInSubmit');
        try {
            if (!identified) {
                console.log('!identified');
                await identify(values.username, form);

                return;
            }
            console.log('identified');
            await signIn(values, form);
        } catch (error) {
            setLoading(false);
            console.log('handleSignInSubmit Error '+error);
            setMessage({
                type: 'error',
                title: i18n.t('auth.error_title'),
                body: i18n.t('auth.error_body'),
                action: () => window.location.reload(),
            });
        }
    };

    /**
     * Identify here after component mounts.
     */
    useEffect(() => {
        if (identified) {
            return;
        }

        const { location } = props;

        const q = UrlUtils.queryParams(location.search);

        if (q.hasOwnProperty('username') && q.username !== '') {
            identify(q.username, {});
        }
    }, [identified]);

    const { classes, ...other } = props;

    return (
        <AuthLayout
            {...other}
            title={
                identified
                    ? i18n.t('navigation.signin_identified_title')
                    : i18n.t('navigation.signin_guest_title')
            }
            subTitle={
                identified ? (
                    <Chip
                        label={username}
                        variant="outlined"
                        icon={<AccountCircleIcon color="primary" />}
                        onDelete={handleUsernameChipClicked}
                        deleteIcon={<ExpandMoreIcon />}
                    />
                ) : (
                    i18n.t('navigation.signin_guest_subtitle')
                )
            }
            loading={loading}
            message={message}
        >
            <Formik
                initialValues={{
                    username,
                    password: '',
                }}
                onSubmit={handleSignInSubmit}
                validationSchema={Yup.object().shape({
                    [!identified
                        ? 'username'
                        : 'password']: Yup.string().required(
                        `The ${
                            !identified ? 'username' : 'password'
                        } field is required`,
                    ),
                })}
            >
                {({ values, handleChange, errors, isSubmitting }) => (
                    <Form autoComplete="off">
                        <Grid container direction="column">
                            {!identified ? (
                                <>
                                    <Grid item className={classes.formGroup}>
                                        <TextField
                                            id="username"
                                            name="username"
                                            label={i18n.t('auth.login')}
                                            placeholder={i18n.t('auth.login')}
                                            value={values.username}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={errors.hasOwnProperty(
                                                'message',
                                            )}
                                            helperText={
                                                errors.hasOwnProperty(
                                                    'message',
                                                ) && errors.message
                                            }
                                        />
                                    </Grid>

                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={6} className={classes.formGroup}>
                                        <Link
                                            component={linkProps => (
                                                <RouterLink
                                                    {...linkProps}
                                                    to={NavigationUtils.route('auth.register')}
                                                />
                                            )}
                                        >
                                            {i18n.t('navigation.register_title')}
                                        </Link>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={6} className={classes.formGroup}>
                                        <Link to="#">
                                            {(
                                                'navigation.forgot_email',
                                            )}
                                        </Link>
                                    </Grid>*/}
                                </Grid>


                                </>
                            ) : (
                                <>
                                    <Grid item className={classes.formGroup}>
                                        <TextField
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            id="password"
                                            name="password"
                                            label={i18n.t('auth.password')}
                                            placeholder={i18n.t('auth.password')}
                                            value={values.password}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                            error={errors.hasOwnProperty(
                                                'password',
                                            )}
                                            helperText={
                                                errors.hasOwnProperty(
                                                    'password',
                                                ) && errors.password
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={i18n.t('auth.visible_password')}
                                                            onClick={
                                                                handlePasswordVisibilityToggle
                                                            }
                                                        >
                                                            {passwordVisible ? (
                                                                <VisibilityOffIcon />
                                                            ) : (
                                                                <VisibilityIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item className={classes.formGroup}>
                                        <Link
                                            component={props => (
                                                <RouterLink
                                                    {...props}
                                                    to={{
                                                        search: UrlUtils.queryString(
                                                            {
                                                                username,
                                                            },
                                                        ),
                                                        pathname: NavigationUtils.route(
                                                            'auth.passwords.request',
                                                        ),
                                                    }}
                                                />
                                            )}
                                        >
                                            {(
                                                i18n.t('navigation.forgot_password')
                                            )}
                                        </Link>
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Grid container justifyContent="space-between">
                            <Grid item />

                            <Grid item className={classes.formGroup}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        (errors &&
                                            Object.keys(errors).length > 0) ||
                                        isSubmitting
                                    }
                                >
                                    {i18n.t('navigation.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
}

const styles = theme => ({
    formGroup: {
        padding: theme.spacing(2),
        paddingTop: 0,
    },
});

export default withStyles(styles)(SignIn);
