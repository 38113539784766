export default {


    /*
    |--------------------------------------------------------------------------
    | Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines contain the default error messages used by
    | the validator class. Some of these rules have multiple versions such
    | as the size rules. Feel free to tweak each of these messages here.
    |
    */


    'name':'DNS Запись',
    'title':'DNS записи для CDN подключений',
    'table_title':'DNS записи',
    'alert_body':'Вы должны перенести эти DNS записи в доменные зоны ваших сайтов.',
    'record':'Запись',
    'type':'Тип',
    'value':'Значение',
    //'':'',

    'domain': 'Домен',
    'user': 'Владелец DNS Записи',
    'user_not_change': 'Пользовватель не может изменить параметр.',
    'dnsrecord_modification': 'Изменение DNS Записи',
    'new_dns_record': 'Новая DNS Запись',
    'edit_dns_record': 'Редактировать DNS Записи',
    'dnsrecord_creation': 'Создание DNS Записи',
    'create_dns_record': 'Создать DNS Запись',

    'help': {
        'superuser':{
            'record':'DNS запись. Субдомен для CDN.',
            'type':'Тип DNS записи',
            'value':'Значение DNS записи.',
            'domain': 'Подключаемый домен',
            'user': 'Владелец DNS записи',
        },
        'user':{
            'record':'DNS запись. Субдомен для CDN.',
            'type':'Тип DNS записи. Пользовватель не может изменить параметр.',
            'value':'Значение DNS записи. Пользовватель не может изменить параметр.',
            'domain': 'Подключаемый домен. Пользовватель не может изменить параметр.',
            'user': 'Владелец DNS записи. Пользовватель не может изменить параметр.',
        },
    },






}