import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import actionsEn from '../lang/en/actions';
import actionsRu from '../lang/ru/actions';
import authEn from '../lang/en/auth';
import authRu from '../lang/ru/auth';
import billingEn from '../lang/en/billing';
import billingRu from '../lang/ru/billing';
import breadcrumbEn from '../lang/en/breadcrumb';
import breadcrumbRu from '../lang/ru/breadcrumb';
import dnsrecordsEn from '../lang/en/dnsrecords';
import dnsrecordsRu from '../lang/ru/dnsrecords';
import homeEn from '../lang/en/home';
import homeRu from '../lang/ru/home';
import navigationEn from '../lang/en/navigation';
import navigationRu from '../lang/ru/navigation';
import paginationEn from '../lang/en/pagination';
import paginationRu from '../lang/ru/pagination';
import passwordsEn from '../lang/en/passwords';
import passwordsRu from '../lang/ru/passwords';
import resourcesEn from '../lang/en/resources';
import resourcesRu from '../lang/ru/resources';
import settingsEn from '../lang/en/settings';
import settingsRu from '../lang/ru/settings';
import siteEn from '../lang/en/site';
import siteRu from '../lang/ru/site';
import tableEn from '../lang/en/table';
import tableRu from '../lang/ru/table';
import validationEn from '../lang/en/validation';
import validationRu from '../lang/ru/validation';


i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: ['en','ru'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    actions: actionsEn,
                    auth: authEn,
                    billing: billingEn,
                    breadcrumb: breadcrumbEn,
                    dnsrecords: dnsrecordsEn,
                    home: homeEn,
                    navigation: navigationEn,
                    pagination: paginationEn,
                    passwords: passwordsEn,
                    resources: resourcesEn,
                    settings: settingsEn,
                    site: siteEn,
                    table: tableEn,
                    validation: validationEn,
                }
            },
            ru: {
                translation: {
                    actions: actionsRu,
                    auth : authRu,
                    billing: billingRu,
                    breadcrumb: breadcrumbRu,
                    dnsrecords: dnsrecordsRu,
                    home: homeRu,
                    navigation: navigationRu,
                    pagination: paginationRu,
                    passwords: passwordsRu,
                    resources: resourcesRu,
                    settings: settingsRu,
                    site: siteRu,
                    table: tableRu,
                    validation: validationRu,
                }
            }
        }
    });

export default i18n;