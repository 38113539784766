export default {

    /*
    |--------------------------------------------------------------------------
    | Action Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in common actions such as
    | button texts, links, message titles & content etc.
    |
    */

    'resources': 'Ресурсы',
    'sites':'Подключения к CDN',
    'dnsrecords':'DNS записи',
    'billing-day':'Биллинг по дням',
    'billing-month':'Биллинг по месяцам',
    'create':'Добавить',
    'edit':'Редактировать',
    'users':'Пользователи',


}