export default {

    /*
    |--------------------------------------------------------------------------
    | Action Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in common actions such as
    | button texts, links, message titles & content etc.
    |
    */

    'back': 'Back',
    'next': 'Next',
    'confirm': 'Confirm',
    'cancel': 'Cancel',
    'continue': 'Continue',
    'retry': 'Retry',
    'undo': 'Undo',
    'actions': 'Actions',
}
