export default {
    'register_title': 'Registration',
    'register_subtitle': 'your Account',
    'password_mismatch': 'The password you entered is incorrect.',
    'failed': "Sorry that didn't work. Please try again.",
    'throttle': 'Too many login attempts. Please try again in :seconds seconds.',
    'login': 'Login',
    'password': 'Password',
    'visible_password': 'Visible password',
    'error_title': 'Something went wrong',
    'error_body': 'Ouch? Something went wrong here. Please try again. ',
    'english_letters': 'Only English letters',
    'firstname': 'Firstname',
    'middlename': 'Middlename',
    'email': 'Email',
    'email_address': 'Email address',
    'enter_valid_email': 'Enter a valid email',
    'confirm_password': 'Confirm password',
    'toggle_password_visibility': 'Toggle password visibility',
    'account_created_title': ':name! Your account has been created.',
    'account_created_body': 'Thank you for registering.',
}