import React, { useContext } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import * as NavigationUtils from '../helpers/Navigation';
import * as UrlUtils from '../helpers/URL';
import { AppContext } from '../AppContext';
import * as Auth from "../views/auth";
import {BACKOFFICE_ROUTES} from "../routers";
import * as Errors from "../views/errors";


const Navigator = props => {
    const { environment, authenticated, username } = useContext(
        AppContext,
    );
    const routes = [
        {
            name: 'auth.signin',
            path: '/signin',
            component: Auth.SignIn,
            auth: false,
        },
        {
            name: 'auth.register',
            path: '/register',
            component: Auth.Register,
        },

        {
            name: 'auth.passwords.request',
            path: '/password/request',
            component: Auth.PasswordRequest,
            auth: false,
        },

        {
            name: 'auth.passwords.reset',
            path: '/password/reset/:token',
            component: Auth.PasswordReset,
            auth: false,
        },

        ...BACKOFFICE_ROUTES,

        {
            name: 'errors.not-found',
            component: Errors.NotFound,
        },
    ];

    return (
        <Switch>
            {routes.map((route, i) => {
                const View = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact
                        render={routeProps => {
                            if (route.auth) {
                                if (!authenticated) {
                                    return (
                                        <Redirect
                                            to={{
                                                search: UrlUtils.queryString({
                                                    username,
                                                }),
                                                pathname: NavigationUtils.route(
                                                    'auth.signin',
                                                ),
                                            }}
                                        />
                                    );
                                }
                            }

                            if (!route.auth && route.hasOwnProperty('path')) {
                                if (authenticated) {
                                    return (
                                        <Redirect
                                            to={NavigationUtils.route(
                                                `${environment}.home`,
                                            )}
                                        />
                                    );
                                }
                            }

                            return <View {...props} {...routeProps} />;
                        }}
                    />
                );
            })}
        </Switch>
    );





   /* const View = Auth.Register;

    return (
        <Switch>

                    <Route
                        //key={i}
                        //path={route.path}
                        exact
                        render={routeProps => {
                                /!*if (!authenticated) {
                                    return (
                                        <Redirect
                                            to={'/signin'}
                                        />
                                    );
                                }

                                if (authenticated) {
                                    return (
                                        <Redirect
                                            to={'/signin'}
                                        />
                                    );
                                }*!/

                            return <View {...props} {...routeProps} />;
                        }}
                    />
                );
        </Switch>
    );*/





};

export default withRouter(Navigator);