export default {

    /*
    |--------------------------------------------------------------------------
    | Settings Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in specifically in actions,
    | links in different kind of settings.
    |
    */

    'profile_updated': 'Profile successfully updated!',
    'profile_not_updated': 'Profile was not updated!',
    'account_password_updated': 'Account Password successfully updated!',
    'account_password_not_updated': 'Account Password was not updated!',
    'account_credentials_updated': 'Account Credentials successfully updated!',
    'account_credentials_not_updated': 'Account Credentials was not updated!',
}
