export default {


    /*
    |--------------------------------------------------------------------------
    | Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines contain the default error messages used by
    | the validator class. Some of these rules have multiple versions such
    | as the size rules. Feel free to tweak each of these messages here.
    |
    */


    'name':'DNS Record',
    'title':'DNS record for CDN connections',
    'table_title':'DNS records',
    'alert_body':'You must copy these DNS records to your site domain zones.',
    'record':'Record',
    'type':'Type',
    'value':'Value',
    'domain': 'Domain',
    'user': 'DNS Record owner',
    'user_not_change': 'User can not change value.',
    'dnsrecord_modification': 'DnsRecord Modification',
    'new_dns_record': 'New DNS Record',
    'edit_dns_record': 'Edit DNS Record',
    'dnsrecord_creation': 'DNS Record Creation',
    'create_dns_record': 'Create a DNS Record',

    'help': {
        'superuser':{
            'record':'DNS record. Subdomain for CDNю',
            'type':'DNS Record type.',
            'value':'DNS record value.',
            'domain': 'Pluggable domain ',
            'user': 'DNS Record owner',
        },
        'user':{
            'record':'DNS record. Subdomain for CDNю',
            'type':'DNS Record type. User can not change value.',
            'value':'DNS record value. User can not change value.',
            'domain': 'Pluggable domain. User can not change value.',
            'user': 'DNS Record owner. User can not change value.',

        },
    },





}