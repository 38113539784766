export default {

    /*
    |--------------------------------------------------------------------------
    | Action Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in common actions such as
    | button texts, links, message titles & content etc.
    |
    */

    'back': 'Назад',
    'next': 'Следующий',
    'confirm': 'Подтвердить',
    'cancel': 'Отменить',
    'continue': 'Продолжить',
    'retry': 'Назад',
    'undo': 'Вперед',
    'actions': 'Действия',
}
