export default {

    /*
    |--------------------------------------------------------------------------
    | Billing Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in navigation links, page titles, form buttons etc.
    |
    */

    'navigation':{
        'billing_day': 'Биллинг по дням',
        'billing_month': 'Биллинг по месяцам'
    },
    'title_day': 'Биллинг по дням',
    'alert_body_day': 'Детальная статистика',
    'table_title_day': 'Таблица статистики по дням',
    'date_day': 'День',

    'title_month': 'Биллинг по месяцам',
    'alert_body_month': 'Эта таблица используется для выставления счетов',
    'table_title_month': 'Таблица статистики по месяцам',
    'date_month': 'Месяц',

    'uncached_size': 'Некэшированный объем',
    'cached_size': 'Кэшированный объем',
    'total_size': 'Суммарный объем',
    'uncached_count': 'Некэшированные файлы',
    'cached_count': 'Кэшированные файлы',
    'total_count': 'Суммарные файлы',
    'dnsrecord': 'DNS запись',
    'site': 'Подключенный сайт',
    'user': 'Владелец',
    'record': 'DNS запись',
    //'': '',

}