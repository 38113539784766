export default {


    /*
    |--------------------------------------------------------------------------
    | Home Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used during authentication for various
    | messages that we need to display to the user. You are free to modify
    | these language lines according to your application's requirements.
    |
    */

    'user':'Hello, :name. Your login :username.',
    'title_summary':'Summary statistic.',
    'title_max_five_billings_by_one_day':'Top 5 sites by traffic volume per day',
    'title_max_five_billings_by_seven_days':'Top 5 sites by traffic volume in seven days',
    'title_five_billings_by_thirty_days':'Top 5 sites by traffic volume in thirty days',
    'username':'Login',
    'user_name':'Name',
    'total_size':'Total volume',
    'record':'DNS record',
    'created_at':'Created at',
    'email':'E-mail',
    'domain':'Domain',
    'origin':'Origin',
    'type':'DNS record type',
    'value':'DNS record value',
    'title_last_five_users':'Last five registered customers',
    'title_last_five_sites':'Last five connected sites',
    'last_five_dnsrecords':'Last five created DNS records',
    'data':{
        'summary_billing_by_last_day':'Total traffic for the last day',
        'summary_billing_by_last_seven_days':'Total traffic for the last seven days',
        'summary_billing_by_last_month':'Total traffic for the last month',
    },
    'user_need_create_site': ':name! You have no connected sites. You need create a new CDN connection.',
    'traffc_on_graf_last_seven_days':'FQDN traffic for the last 7 days',
    'seeds_on_map_last_seven_days':'Seeds of all FQDNs on the map for the last 7 days',
    'mb_last_seven_days':'Mb',




}