export default {


    /*
    |--------------------------------------------------------------------------
    | Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines contain the default error messages used by
    | the validator class. Some of these rules have multiple versions such
    | as the size rules. Feel free to tweak each of these messages here.
    |
    */


    'accept': ':attribute должен быть принят.',
    'active_url': ':attribute не является допустимым URL.',
    'after': ':attribute должен быть датой после: date.',
    'after_or_equal': ':attribute должен быть датой после или равной дате.',
    'alpha': ':attribute может содержать только буквы.',
    'alpha_dash': ':attribute может содержать только буквы, цифры, дефисы и подчеркивания.',
    'alpha_num': ':attribute может содержать только буквы и цифры.',
    'array': ':attribute должен быть массивом.',
    'before': ':attribute должен быть датой до: date.',
    'before_or_equal': ':attribute должен быть датой до или равной дате.',
    'between': {
        'numeric': ':attribute должен быть между :min и :max.',
        'file': ':attribute должен находиться в диапазоне от :min до :max килобайт.',
        'string': ':attribute должен содержать символы :min и :max.',
        'array': ':attribute должен содержать от :min до :max элементов.',
    },
    'boolean': 'Поле :attribute должно быть истинным или ложным.',
    'confirmed': 'Подтверждение :attribute не совпадает.',
    'date': ':attribute не является допустимой датой.',
    'date_format': ':attribute не соответствует формату :format.',
    'different': ':attribute и :other должны быть разными.',
    'digits': ':attribute должен быть :digits digits.',
    'digits_between': ':attribute должен быть между :min и :max цифрами.',
    'dimensions': ':attribute имеет недопустимые размеры изображения.',
    'distinct': 'Поле :attribute имеет повторяющееся значение.',
    'email': ':attribute должен быть действующим адресом электронной почты.',
    'exists': 'Выделенный :attribute недействителен.',
    'file': ':attribute должен быть файлом.',
    'filled': 'Поле :attribute должно иметь значение.',
    'gt': {
        'numeric': ':attribute должен быть больше, чем :value.',
        'file': ':attribute должен быть больше, чем :value в килобайтах.',
        'string': ':attribute должен быть больше, чем символов :value.',
        'array': ':attribute должен иметь более :value элементов.',
    },
    'gte': {
        'numeric': ':attribute должен быть больше или равен :value.',
        'file': ':attribute должен быть больше или равен :value в килобайтах.',
        'string': ':attribute должен быть больше или равен :value символов.',
        'array': ':attribute должен иметь :value значения или более.',
    },
    'image': ':attribute должен быть изображением.',
    'in': 'Выделенный :attribute недействителен.',
    'in_array': 'Поле :attribute не существует в :other.',
    'integer': ':attribute должен быть целым числом.',
    'ip': ':attribute должен быть действительным IP-адресом.',
    'ipv4': ':attribute должен быть действительным IPv4-адресом.',
    'ipv6': ':attribute должен быть действительным IPv6-адресом.',
    'json': ':attribute должен быть допустимой строкой JSON.',
    'lt': {
        'numeric': ':attribute должен быть меньше :value.',
        'file': ':attribute должен быть меньше :value килобайт.',
        'string': ':attribute должен содержать меньше символов :value.',
        'array': ':attribute должен содержать меньше :value элементов.',
    },
    'lte': {
        'numeric': ':attribute должен быть меньше или равен :value.',
        'file': ':attribute должен быть меньше или равен :value в килобайтах.',
        'string': ':attribute должен содержать меньше или равно символов :value.',
        'array': ':attribute не может содержать более :value элементов.',
    },
    'max': {
        'numeric': ':attribute не может быть больше, чем :max.',
        'file': ':attribute не может быть больше :max килобайт.',
        'string': ':attribute не может быть больше, чем :max символов.',
        'array': ':attribute не может содержать более :max элементов.',
    },
    'mimes': ':attribute должен быть файлом типа: :values.',
    'mimetypes': ':attribute должен быть файлом типа: :values.',
    'min': {
        'numeric': ':attribute должен быть не меньше: мин.',
        'file': ':attribute должен быть не меньше :min килобайт.',
        'string': ':attribute должен содержать не менее :min символов.',
        'array': ':attribute должен содержать как минимум :min элементов.',
    },
    'not_in': 'Выделенный :attribute недействителен.',
    'not_regex': 'Недействительный формат :attribute.',
    'numeric': ':attribute должен быть числом.',
    'present': 'Поле :attribute должно присутствовать.',
    'pwned': ':attribute слабый, введите надежный пароль.',
    'regex': 'Недействительный формат :attribute.',
    'required': 'Поле :attribute обязательно для заполнения.',
    'required_if': 'Поле :attribute необходимо, когда :other есть :value.',
    'required_unless': 'Поле :attribute является обязательным, если :other отсутствует в :values.',
    'required_with': 'Поле :attribute обязательно, если присутствует :values.',
    'required_with_all': 'Поле :attribute необходимо, когда :values присутствуют.',
    'required_without': 'Поле :attribute является обязательным, если :values отсутствует.',
    'required_without_all': 'Поле :attribute является обязательным, если ни одно из :values не присутствует.',
    'same': ':attribute и :other должны совпадать.',
    
    'size': {
        'numeric': ':attribute должен быть :size.',
        'file': ':attribute должен быть :size в килобайтах.',
        'string': ':attribute должен содержать символы :size.',
        'array': ':attribute должен содержать элементы :size.',
    },

    'string': ':attribute должен быть строкой.',
    'timezone': ':attribute должен быть допустимой зоной.',
    'unique': ':attribute уже занят.',
    'uploaded': ':attribute не удалось загрузить.',
    'url': 'Недопустимый формат :attribute.',
    'uuid': ':attribute должен быть действительным UUID.',
    /*
    |--------------------------------------------------------------------------
    | Custom Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | Here you may specify custom validation messages for attributes using the
    | convention "attribute.rule" to name the lines. This makes it quick to
    | specify a specific custom language line for a given attribute rule.
    |
    */

    'custom': {
        'attribute-name': {
            'rule-name': 'custom-message',
        },
    },

    /*
    |--------------------------------------------------------------------------
    | Custom Validation Attributes
    |--------------------------------------------------------------------------
    |
    | The following language lines are used to swap our attribute placeholder
    | with something more reader friendly such as "E-Mail Address" instead
    | of "email". This simply helps us make our message more expressive.
    |
    */

    'attributes': {},

}
