export default {

    /*
    |--------------------------------------------------------------------------
    | Action Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in common actions such as
    | button texts, links, message titles & content etc.
    |
    */

    'resources': 'Resources',
    'sites':'Sites',
    'dnsrecords':'DNS records',
    'billing-day':'Billing by day',
    'billing-month':'Billing by month',
    'create':'Create',
    'edit':'Edit',
    'users':'Users',


    }