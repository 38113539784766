export default {

    /*
    |--------------------------------------------------------------------------
    | Table Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in table pagination controls, table toolbar forms etc.
    |
    */

    // Filters
    'filter_list': 'Filter the list',
    'filter_by_label': 'Column',
    'filter_type_label': 'Condition',
    'filter_value_label': 'Value',
    'string_value_placeholder': 'String value here',
    'numeric_value_placeholder': 'Numeric value here',
    'add_filter': 'Add Filter',
    'eqs': 'Is equal to',
    'neqs': 'Is not equal to',
    'gt': 'Greater than',
    'lt': 'Less than',
    'gte': 'Greater than equals to',
    'lte': 'Less than equals to',
    'like': 'Contains',
    'nlike': 'Does not contain',

    // Sorting
    'sort_asc': 'Sort Ascending',
    'sort_desc': 'Sort Descending',

    // Pagination controls
    'per_page': 'Rows per page',
    'first_page': 'First Page',
    'previous_page': 'Previous Page',
    'next_page': 'Next Page',
    'last_page': 'Last Page',
}
