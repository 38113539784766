export default {

    /*
    |--------------------------------------------------------------------------
    | Settings Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in specifically in actions,
    | links in different kind of settings.
    |
    */

    'profile_updated': 'Профиль успешно обновлен!',
    'profile_not_updated': 'Профиль не был обновлен!',
    'account_password_updated': 'Пароль учетной записи успешно обновлен!',
    'account_password_not_updated': 'Пароль учетной записи не был обновлен!',
    'account_credentials_updated': 'Учетные данные успешно обновлены!',
    'account_credentials_not_updated': 'Учетные данные не обновлены!',
}
