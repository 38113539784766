export default {

    /*
    |--------------------------------------------------------------------------
    | Resource Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in resource links, actions etc.
    |
    */

    'create': 'Add :name',
    'show': 'View :name',
    'edit': 'Edit :name',
    'delete': 'Delete :name',
    'edit_image': "Edit :name's image",

    'delete_confirmation_title': 'You are deleting a :name',
    'delete_confirmation_body': "If not undone, the :name won't be recovered anymore.",

    'fetched': 'Fetched :total :names.',
    'not_fetched': 'Error fetching :names!',
    'created': ':name sucessfully created!',
    'not_created': 'Error creating :name!',
    'updated': ':name sucessfully updated!',
    'not_updated': 'Error updating :name!',
    'deleted': ':name successfully deleted!',
    'not_deleted': 'Error deleting :name!',
    'restored': ':name successfully restored!',
    'not_restored': 'Error restoring :name!',


    'create_user': 'Create user',
    'List': 'Page',
}
