export default {
    'register_title': 'Регистрация',
    'register_subtitle': 'Вашего личного кабинета',
    'password_mismatch': 'Вы ввели неверный пароль.',
    'failed': "Извините, что не сработало. Пожалуйста, попробуйте еще раз.",
    'throttle': 'Слишком много попыток входа в систему. Повторите попытку через: секунды секунды.',
    'login': 'Логин',
    'password': 'Пароль',
    'visible_password': 'Переключить видимость пароля',
    'error_title': 'Что-то пошло не так',
    'error_body': 'Ой? Здесь что-то пошло не так. Пожалуйста, попробуйте еще раз.',
    'english_letters': 'Только английские буквы',
    'firstname': 'Имя',
    'middlename': 'Очество',
    'email': 'Email',
    'email_address': 'Email адрес',
    'enter_valid_email': 'Введите правильный email',
    'confirm_password': 'Повторный пароль',
    'toggle_password_visibility': 'Переключить видимость пароля',
    'account_created_title': ':name! Ваш аккаунт создан.',
    'account_created_body': 'Списибо за регистрацию.',
}