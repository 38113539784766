export default {


    /*
    |--------------------------------------------------------------------------
    | Validation Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines contain the default error messages used by
    | the validator class. Some of these rules have multiple versions such
    | as the size rules. Feel free to tweak each of these messages here.
    |
    */


    'page_title': 'Подключение сайта к CDN',
    'page_first_title': 'Домен, Ориджин, Кеш',
    'page_second_title': 'Фильтры, Авторизация, Сжатие, G2O',
    'page_third_title': 'Webp, Сhannels, SSL',
    'domain': 'Домен',
    'origin': 'Ориджин',
    'cdn_connection': 'подключение к CDN',
    'cdn_connections': 'Подключения к CDN',
    'user': 'Владелец сайта',

    'label':{

        'domain': 'Домен',

        'origin_rules_pattern':'Правила перезаписи URI',
        'origin_rules_match':'Правила перезаписи URI - подстановка',

        'immutable_files':'Неизменяемые файлы',
        'access_control_allow_origin':'Контроль доступа для Ориджина',

        'client_headers':'Постоянный заголовок для клиента',
        'origin_headers':'Постоянный заголовок для сайта',

        //Cache
        'cache':'Размер пула оперативной памяти',
        'cache_control':'Управление кэшем',
        'client_no_cache':'Управление кэшем',

        'cache_errors_code':'HTTP код ошибки (cache_error_code)',
        'cache_errors_timeout':'HTTP код ошибки (cache_errors_timeout)',

        'cache_errors_permanent_code':'HTTP код ошибки (cache_error_permanent_code)',
        'cache_errors_permanent_timeout':'HTTP код ошибки (cache_errors_permanent_timeout)',

        'cache_without_parameters':'Кэширование ресурсов сайта',
        'sub_domains':'Поддомены',



        /* PAGE 2 */

        'user_agent':'HTTP Заголовок User-Agent',
        'override_user_agent':'Флаг User-Agent',

        'filter_uri_uri':'Фильтр URI - регулярное выражение',
        'filter_uri_code':'Фильтр URI- код ошибки',
        'white_uri':'Разрешенные URI',

        'client_ip_header':'HTTP заголовок',

        'proxy':'Прокси для сайта',

        //Auth
        'auth_uri':'Авторизация доступа',
        'auth_param':'Авторизация доступа - параметр',
        'auth_timeout':'Авторизация доступа - таймаут',

        'compress_disabled':'Запрет сжатия',

        'http2_client':'Значение для текущего сайта',
        'http2_client_prior_knowledge':'Значение для текущего сайта prior knowledge',
        'http2_server':'Значение для текущего сайта server',

        'g2o_token':'G2O токен',
        'g2o_password':'G2O пароль',

        /* PAGE 3 */

        //webp
        //Описывает настройки сжатия изображений webp
        'webp_user_agents':'Контент webp',
        'webp_default':'Упаковка изображений в webp',
        'webp_lossless':'Упаковка изображений в webp без потери качества',
        'webp_near_lossless_pattern':'Webp без потери качества - pattern',
        'webp_near_lossless_value':'Webp без потери качества - value',
        'webp_loss_pattern':'Webp с потерей качества - pattern',
        'webp_loss_value':'Webp с потерей качества - value',

        //channels
        'channels_patterns':'Шаблоны URI',
        'channels_timeout':'Таймаут отключения',

        'origin_slave':'IP сида в режиме прокси',

        //ssl
        'ssl_key':'SSL ключ',
        'ssl_cert':'SSL сертификат',
        'user': 'Владелец сайта'

    },


    'help': {

        'domain': 'Введите доменное имя вашего сайта без www.',

        'origin_rules_pattern':'Маска (pattern) перезаписи URI при доступе к ресурсам сайта, в формате регулярных выражений %N%.',
        'origin_rules_match':'Правило подстановки для модификации URI, если URI соответствует правилу, заданному в предыдущем поле.',

        'immutable_files':'Если ресурс соответствует заданному регулярному выражению, то на данный ресурс не будет действовать опция “cache-control = true”.',
        'access_control_allow_origin':'Если заголовок “Origin:”, отправленный от клиента к узлу раздачи, соответствует заданному регулярному выражению, - то в ответе от узла раздачи к клиенту будет выставляется значение заголовка “access-control-allow-origin”, равное значению этого “Origin:”.',

        'client_headers':'Фиксированный заголовок, который всегда будет передаваться от узла раздачи клиенту.',
        'origin_headers':'Фиксированный заголовок, который всегда будет передаваться от узла раздачи сайту.',

        //Cache
        'cache':'Размер пула оперативной памяти, используемого для кэширования ресурсов сайта, в мегабайтах (по умолчанию - 50).',
        'cache_control':'Если включено - то время жизни кэша задаётся заголовками “expires cache-control”.',
        'client_no_cache':'Если включено - то клиенту всегда отдаётся заголовок “cache-control: no-cache” (никакие другие заголовки кэширования не отдаются).',

        'cache_errors_code':'При получении от сайта HTTP-ошибки с заданным кодом, повторный запрос этого же ресурса у сайта будет возможен не ранее заданного тайм-аута.',
        'cache_errors_timeout':'Значение тайм-аута, регулирующего возможность повторного запроса ресурса в случае, если предыдущий запрос завершился HTTP-ошибкой с заданным кодом.',

        'cache_errors_permanent_code':'При получении от сайта HTTP-ошибки с заданным кодом, повторный запрос этого же ресурса у сайта будет возможен не ранее заданного тайм-аута.',
        'cache_errors_permanent_timeout':'Значение тайм-аута, регулирующего возможность повторного запроса ресурса в случае, если предыдущий запрос завершился HTTP-ошибкой с заданным кодом.',

        'cache_without_parameters':'Если включено - то кэширование ресурсов сайта будет происходить по URI без параметров; иначе - по полному URI.',

        'sub_domains':'Если включено - то любому субдомену FQDNS будет ставиться в соответствие сам исходный origin.',



        /* PAGE 2 */

        'user_agent':'Устанавливаемый HTTP-заголовок User-Agent для данного сайта (действует только в случае, если включён флаг User-Agent в следующем поле).',
        'override_user_agent':'Если включено - то на сайт передаётся значение, заданное в предыдущем поле; если выключено - то HTTP-заголовок “user-agent” формируется узлом раздачи автоматически.',

        'filter_uri_uri':'Список из регулярных выражений, задающих набор uri, которые должны приводить к генерации ошибок.',
        'filter_uri_code':'Список кодов ошибок, генерируемых при совпадении uri с одним из значений, перечисленных в предыдущем поле.',
        'white_uri':'Список регулярных выражений для определения URI, разрешённых к запросу. Если URI не соответствует регулярному выражению - клиенту будет возвращаться код ошибки 404.',

        'client_ip_header':'Если параметр задан - то на сайт будет передаваться HTTP- заголовок “XXX: y.x.y.z” где XXX - заданное в этом поле значение, а y.x.y.z - IP-адрес клиента, запросившего ресурс.',

        'proxy':'Если true включает режим прокси для сайта.',

        //Auth
        'auth_uri':'Список регулярных выражений, задающих uri ресурсов, для которых требуется авторизация доступа.',
        'auth_param':'Список параметров, при наличии которых во входящих uri ресурсов (при условии, что эти uri соответствуют регулярных выражениям, заданным в предыдущем поле), требуется авторизация доступа. При ошибке авторизации ответе пользователю возвращается ошибка 500, и этот ответ кэшируется на тайм-аут, заданный в следующем поле.',
        'auth_timeout':'Значение тайм-аута для кэширования ошибки 500.',

        'compress_disabled':'Запрет сжатия контента для указанных компрессоров.',

        'http2_client':'При наличии - перезаписывает глобальную настройку “http2_client”',
        'http2_client_prior_knowledge':'При наличии - перезаписывает глобальную настройку “http2_client_prior_knowledge”.',
        'http2_server':'При наличии - перезаписывает глобальную настройку “http2_server”.',

        'g2o_token':'Значение токена для генерации узлом раздачи соответствующего заголовка Akamai G2O. Akamai g2o',
        'g2o_password':'Значение пароля для генерации узлом раздачи соответствующего заголовка Akamai G2O.',

        /* PAGE 3 */

        //webp
        //Описывает настройки сжатия изображений webp
        'webp_user_agents':'Список регулярных выражений (user-agent), для которых узел раздачи отдаёт графику в формате WEBP. (В случае пустого списка - WEBP отдаётся всегда.)',
        'webp_default':'Список регулярных выражений, задающих имена файлов, для которых узел раздачи отдаёт графику в формате WEBP.',
        'webp_lossless':'Список регулярных выражений, для которых включена упаковка соответствующих изображений в WEBP без потери качества.',
        'webp_near_lossless_pattern':'Список массив регулярных выражений, для которых включена упаковка соответствующих изображений в WEBP с минимальной потерей качества, с коэффициентом предобработки, заданном в поле “value”.',
        'webp_near_lossless_value':'Коэффициент предобработки WEBP: от 0 (максимальная предобработка) до 100 (без предобработки).',
        'webp_loss_pattern':'Список массив регулярных выражений, для которых включена упаковка соответствующих изображений в WEBP с минимальной потерей качества, с коэффициентом предобработки, заданном в поле “value”.',
        'webp_loss_value':'Коэффициент предобработки WEBP: от 0 (максимальная предобработка) до 100 (без предобработки).',

        //channels
        'channels_patterns':'Задает шаблон URI, по которому определяется идентификатор канала.',
        'channels_timeout':'Таймаут, по истечении которого пользователь считается отключённым от канала.',

        'origin_slave':'IP узла раздачи, работающего в режиме прокси, для непосредственного получения контента (вместо получения его с сервера).',

        //ssl
        'ssl_key':'SSL ключ для HTTPS.',
        'ssl_cert':'SSL сертификат для HTTPS.',

        'user': 'Логин владельца подключаемого сайта'

        },


    'check': {
        'domain_can_not_check': 'Домен :name не возможно проверить! Пожалуйста, обратитесь в службу поддержки.',
        'domain_not_registered': 'Домен :name не зарегистрирован!',
        'domain_not_delegated': 'Хостинг :name не найден! Добавте DNS запись A на ваш хостинг.',
        'domain_was_added_earl': 'Домен :name был добавлен ранее!',
        'domain_not_hosting': 'Домен не подключен к хостингу!',
    },


}