export default {

    /*
    |--------------------------------------------------------------------------
    | Billing Language Lines
    |--------------------------------------------------------------------------
    |
    | The following language lines are used in the frontend of the application,
    | specifically in navigation links, page titles, form buttons etc.
    |
    */

    'navigation': {
        'billing_day': 'Billing by days',
        'billing_month': 'Billing by months'
    },

    'title_day': 'Billing by days',
    'alert_body_day': 'Detailed statistics',
    'table_title_day': 'Billing table by days',
    'date_day': 'Day',

    'title_month': 'Billing by months',
    'alert_body_month': 'This table is used for invoicing',
    'table_title_month': 'Billing table by months',
    'date_month': 'Month',

    'uncached_size': 'Uncached volume',
    'cached_size': 'Cached volume',
    'total_size': 'Total volume',
    'uncached_count': 'Uncached files',
    'cached_count': 'Cached files',
    'total_count': 'Total files',
    'dnsrecord': 'DNS record',
    'site': 'Connected domain',
    'user': 'Owner',
    'record': 'DNS record',

}