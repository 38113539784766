import './js/bootstrap';

import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';

import { register as registerServiceWorker } from './js/helpers/ServiceWorker';
import App from './App';
import { dark as darkTheme, light as lightTheme } from './js/themes/backoffice';



if (document.querySelector('#initial-content')) {
    document.querySelector('#initial-content').outerHTML = '';
}

if (document.querySelector('#root')) {
    if (process.env.NODE_ENV === 'development') {
        ReactDOM.render(
                <App
                    environment="backoffice"
                    darkTheme={darkTheme}
                    lightTheme={lightTheme}
                />
            ,
            document.querySelector('#root'),
        );
    } else {
        ReactDOM.render(
            <HttpsRedirect>
                <App
                    environment="backoffice"
                    darkTheme={darkTheme}
                    lightTheme={lightTheme}
                />
            </HttpsRedirect>
            ,
            document.querySelector('#root'),
        );
    }

}

const swFilepath = document.querySelector('meta[name=sw-filepath]');



if (swFilepath) {
    registerServiceWorker(swFilepath.content);
}
